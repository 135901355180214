/******************************* Global styles *******************************/
* {
  margin: 0;
}

:root {
  --primary: orange;
  --secondary: #000;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: 'Manrope', sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.75em;
  font-weight: 500;
  margin-bottom: 10px;
}

/* h2 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 5px;
  margin-top: 0.25em;
} */

h2 {
  font-size: 2em; /* Starting size, but could adjust based on your base font-size */
  font-weight: 500;
  letter-spacing: 0.3125em; /* Converted to em based on a base font-size, for scalability */
  margin-top: 0.25em;
}

/* Smaller devices (phones, tablets) */
@media (max-width: 768px) {
  h2 {
    font-size: 1.75em; /* Slightly smaller on small devices */
    letter-spacing: 0.25em; /* Adjust letter-spacing for smaller screens */
  }
}

/* Very small devices */
@media (max-width: 480px) {
  h2 {
    font-size: 1.5em; /* Even smaller on very small devices */
    letter-spacing: 0.2em; /* Further reduce letter-spacing */
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container, .counter-container, .counter-item {
    flex-direction: column;
    align-items: center;
  }
  h2, p {
    font-size: 1.5em; /* Adjust font size */
    text-align: center; /* Center text for readability */
  }
  .counter-icon {
    font-size: 3em; /* Larger icons for visibility */
  }
  img {
    width: 100%; /* Adjust image width to be responsive */
    height: auto; /* Maintain aspect ratio */
  }
}



/******************************* Layout styles *******************************/
.root-layout, .blogLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header, footer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: #000;
  color: #fff;
}

/******************************* Header styles *******************************/
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: auto;
}

.header-content {
  width: 100%;
  padding: 0 100px;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

header nav {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-right: auto;
}

header img {
  margin-right: auto;
  max-height: 50px;
}

header nav a {
  text-decoration: none;
  padding: 6px;
  color: #fff;
}

header nav a:hover {
  color: orange;
}

.root-layout header nav span.login-button:hover {
  color: orange !important;
}

.nav-link {
  cursor: pointer;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center align text and icons */
  text-decoration: none;
  position: relative;
  height: 40px;
}

.nav-link.active-nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: orange;
}


.nav-link:hover {
  color: orange;
}

/* .active-nav-link {
  border-bottom: 2px solid orange;
} */

.active-submenu {
  border-bottom: 2px solid orange; /* Change the border style and color as needed */
}

.secondaryNav {
  background-color: rgba(0, 0, 0, 0.1);
}

.secondaryNav nav {
  max-width: 800px;
  margin: 0 auto;
}

.secondaryNav nav a {
  text-decoration: none;
  padding: 6px;
  color: #000;
}

.secondaryNav nav a:hover {
  color: orange;
}

main {
  flex-grow: 4;
  flex-shrink: 0;
  flex-basis: auto;
}

.rounded-button {
  background-color: orange;
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.rounded-button:hover {
  background-color: darkorange;
}


/******************************* Submenu styles *********************************/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  padding: 10px;
  width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.submenu a {
  display: block;
  color: white;
  text-decoration: none;
  border-bottom: none;
}

.submenu a:hover {
  color: #FFA500;
  border-bottom: none
}

.nav-link:hover .submenu {
  display: block;
}

/* Styles for the nav item (Home link) */
.nav-item {
  position: relative;
  display: inline-block; /* or 'flex', depending on your layout */
}

/* Initially hide the submenu */
.home-submenu {
  display: none;
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 100%; /* Adjust as needed */
  z-index: 100; /* Ensure it's above other content */
}

/* Show the submenu when hovering over the nav item */
.nav-item:hover .home-submenu {
  display: block;
}

.user-dropdown,
.about-dropdown,
.admin-dropdown {
  position: relative;
}

/* Add this CSS to your stylesheets */
.user-submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  border: none;
  padding: 10px;
  width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: none;
}

.user-submenu .sign-out {
  color: white;
  cursor: pointer;
  transition: color 0.3s;
}

.user-submenu .sign-out:hover {
  color: orange;
}

.user-dropdown:hover .user-submenu {
  display: block;
}


/******************************** Breadcrumbs ********************************/
.breadcrumbs {
  max-width: 1200px;
  margin: 0 auto;
}

.breadcrumbs > * {
  display: inline-block;
  margin-right: 5px;
}

.breadcrumbs .crumb::after {
  content: '>';
  margin-left: 5px;
}

.breadcrumbs .crumb:last-child::after {
  display: none;
}

.breadcrumbs .crumb a {
  color: #333;
}

/***************************** Hero Section styles ***************************/
.heroSlider {
  margin: 0 auto;
  height: 100vh;
}

.heroTitleArea {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
}

.heroTitle {
  font-size: 10vw;
  letter-spacing: 0.1em;
  text-align: center;
}

.heroSubtitle {
  font-size: 1.5vw;
  text-align: center;
}

/****************************** Content styles *******************************/
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.column {
  float: left;
  padding: 10px;
}

.left, .right {
  width: 15%;
}

.centeredText {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-size: 1.5vw;
}

.errorBlock {
  max-width: 50%;
  padding: 10px;
  border-radius: 6px;
}

/******************************** Blog styles ********************************/
.blogPreview {
  display: flex;
  margin: 20px 0;
  border-top: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.05), -1px -3px 5px rgba(0, 0, 0, 0.05), 0 4px 5px rgba(0, 0, 0, 0.05);
}

.blogPreview:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1), -1px -3px 5px rgba(0, 0, 0, 0.1);
}

.blogImage {
  flex: 0 0 267px;
  background-color: #f0f0f0;
}

.blogImage img {
  width: 100%;
  height: 267px;
  object-fit: cover;
}

.blogContent {
  flex: 1;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
}

.blogContent h2 {
  font-size: 1.5em;
}

.blogTitleLink {
  text-decoration: none;
  color: inherit;
}

.blogMeta {
  display: flex;
  flex-direction: row;
}

.metaText {
  color: #737C8C;
  text-transform: uppercase;
}

.boxed {
  background-color: #ebedf1;
  font-size: 10px;
  margin-right: 5px;
  padding: 2px 5px;
  text-transform: uppercase;
  border-radius: 4px;
  align-self: center;
}

.blogSummary {
  margin: 10px 0;
}

.blog {
  width: 800px;
  margin: 40px auto;
}

.blogPreview h2:hover {
  color: orange;
}

.blogPreview Link:visited {
  color: red;
}

.blogDetails {
  max-width: 800px;
  margin: 10px auto;
}

.blogDetails h3 {
  margin-top: 0.6em;
  margin-bottom: 0.25em;
}

.blogDetails .blogMeta {
  margin-bottom: 20px;
}

.blogDetails .metaText {
  font-size: 16px;
  margin-right: 5px;
}

.blogDetails p {
  font-size: 16px;
  line-height: 1.68;
}

.blogDetails span {
  font-size: 30px;
  font-weight: 900;
}

.blogDetails hr {
  margin: 1em 0;
  background: none;
  border: none;
  text-align: center;

  &::before {
    content: "\2022 \2022 \2022";
    font-size: 30px;
    letter-spacing: 2em;
    padding-left: 2em;
  }
}

/* Search styling */

.search-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
}

.search-group {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-left: 10px;
}

.search-button {
  padding: 15px 20px;
  height: 100%;
  border: none;
  background-color: orange;
  color: white;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
  outline: none;
  border-left: 1px solid #ccc;
}

.input-group-addon {
  padding: 10px;
  background-color: transparent;
  border: none;
  color: #555;
}

.search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
}

/* Hover effect for button */
.search-button:hover {
  background-color: darkorange;
}

/* Adding some responsiveness */
@media (max-width: 768px) {
  .search-button {
    padding: 10px;
  }
  .input-group-addon, .search-input, .search-button {
    padding: 8px;
  }
}




/***************************** Error Page styles *****************************/
.blogError {
  width: 800px;
  margin: 40px auto;
}

.blogError p {
  font-size: 16px;
  line-height: 1.68;
}

.top10 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.top10 p {
  padding-left: 3em;
  width: 75%;
}

.content:after {
  content: "";
  display: table;
  clear: both;
}

/******************************* Footer styles *******************************/
footer div {
  display: flex;
  justify-content: start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

/****************************** About Us styles ******************************/
.about {
  width: 800px;
  margin: 40px auto;
}

.about p {
  padding-bottom: 10px;
}

.about hr {
  margin: 1em 0;
  background: none;
  border: none;
  text-align: center;

  &::before {
    content: "\2022 \2022 \2022";
    font-size: 30px;
    letter-spacing: 2em;
    padding-left: 2em;
  }
}


/**************************** Contact form styles ****************************/
.contact, .thankYou {
  width: 800px;
  margin: 40px auto;
}

.contact p {
  margin-bottom: 10px;
}

.thankYou p {
  margin-bottom: 10px;
}

form {
  margin-top: 30px;
}

form input, form label span, form textarea {
  display: block;
}

form select {
  margin-bottom: 30px;
  padding: 8px;
}

form input, form textarea {
  margin-bottom: 30px;
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #737C8C;
  width: 100%;
}

form label span {
  margin-bottom: 10px;
}

/***************************** Admin form styles *****************************/
.admin {
  width: 800px;
  margin: 0 auto;
}

/******************************** Card styles ********************************/
.seasonCard, .teamCard {
  padding: 10px 16px;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 0;
  flex-wrap: wrap;
  border: 1px solid #737C8C;
  border-radius: 12px;
  width: 220px;
}

.sportCard {
  border-bottom: solid 1px; 
  border-bottom-color: #d3d3d3; 
  width: 100%; 
  padding-bottom: 5px;
  margin-bottom:  10px;
}

/******************************* Modal styles ********************************/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  position: relative;
  text-align: center;
}

.close-button {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.close-button:hover {
  background-color: darkorange;
}

/* .form-container {
  padding: 20px;
  position: relative;
} */

.form-heading {
  font-size: 2em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  position: relative;
  margin-bottom: 30px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.input-group input {
  width: calc(100% - 20px); 
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.submit-button-disabled {
  background-color:#d0d0d0;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  width: 80%;
  font-size: 16px;
  margin: 15px auto;
}

.login-button,
.submit-button { 
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  width: 80%;
  font-size: 16px;
  margin: 15px auto;
}

.login-button:hover,
.submit-button:hover {
  background-color: darkorange;
}  

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.links a {
  color: var(--primary); 
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
}

.links a:hover {
  text-decoration: underline;
}

.link-separator {
  color: #ccc;
  margin: 0 5px;
}

.error-message {
  /* position: absolute; */
  /* bottom: -20px; */
  margin-top: -10px;
  /* background-color: pink; */
  left: 0;
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.verification-code-container p {
  /* color: red; */
  margin-bottom: 20px;
}

/***************************** Additional Styles *****************************/
.password-requirements {
  list-style-type: none;
  margin-top: 10px;
  padding-left: 20px;
  font-size: 14px;
  text-align: left;
}

.password-requirements li::before {
  content: '\2718';
  margin-right: 5px;
  color: red;
}

.password-requirements li.valid::before {
  content: '\2713';
  margin-right: 5px;
  color: green;
  font-weight: bold;
}

.password-requirements.all-met {
  display: none;
}


/********************************** Counters *********************************/
.counter-container {
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  padding: 100px 0;
  margin: auto 200px;
}

.counter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.counter-icon {
  font-size: 3em;
  color: orange;
}

.counter-number {
  font-size: 2em;
  font-weight: bold;
  color: orange;
}

.counter-label {
  font-size: 1em;
  font-weight: bold;
  color: orange;
}


/********************************** Accordians *********************************/
.amplify-accordion {
  display: block;
  background-color: var(--amplify-components-accordion-background-color);
  border-radius: var(--amplify-components-accordion-item-border-radius);
}

.amplify-accordion__item:where(:first-of-type) {
  border-start-end-radius: var(--amplify-components-accordion-item-border-radius);
  border-start-start-radius: var(--amplify-components-accordion-item-border-radius);
}

.amplify-accordion__item__trigger {
  cursor: pointer;
  display: flex;
  position: relative;
  color: var(--amplify-components-accordion-item-trigger-color);
  border-radius: var(--amplify-components-accordion-item-border-radius);
  padding-inline: var(--amplify-components-accordion-item-trigger-padding-inline);
  padding-block: var(--amplify-components-accordion-item-trigger-padding-block);
  gap: var(--amplify-components-accordion-item-trigger-gap);
  align-items: var(--amplify-components-accordion-item-trigger-align-items);
  /* justify-content: var(--amplify-components-accordion-item-trigger-justify-content); */
  justify-content: space-between;
}

.accordion-trigger {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
}

[open] .amplify-accordion__item__icon {
  transform: rotate(180deg);
}

.amplify-accordion__item__icon {
  color: var(--amplify-components-accordion-item-icon-color);
  transition-property: transform;
  transition-duration: var(--amplify-components-accordion-item-icon-transition-duration);
  transition-timing-function: var(--amplify-components-accordion-item-icon-transition-timing-function);
}

.amplify-icon>svg {
  width: var(--amplify-components-icon-height);
  color: #737C8C;
}

.amplify-icon, .amplify-icon>svg {
  height: var(--amplify-components-icon-height);
}

.amplify-icon {
  display: inline-block;
  vertical-align:auto;
  fill: currentColor;
  line-height: var(--amplify-components-icon-line-height);
}

.sport-description {
  /* background-color: pink; */
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fa-icon {
  height: 0.75em;
  color: #737C8C;
}

/* Default styles for the position list */
.position-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Styles for two columns */
.position-list.two-columns {
  column-count: 2; /* Display in 2 columns */
  column-gap: 16px; /* Adjust the gap between columns as needed */
}

/* Styles for three columns */
.position-list.three-columns {
  column-count: 3; /* Display in 3 columns */
  column-gap: 16px; /* Adjust the gap between columns as needed */
}

/******************************** Table styles *******************************/
Table {
  width: 100%;
  /* background-color: pink; */
}

.amplify-table__th {
  background-color: #737C8C;
  color: #fff;
  text-align: left;
  font-size: 20px;
  padding-bottom: 2px;
  padding-left: 5px;
  /* border-bottom: solid #737C8C; */
}

.amplify-table__td {
  font-size: 15px;
  text-align: left;
  padding-left: 5px;
  padding-right: 10px;
  /* padding-top: 20px; */
}

.amplify-table__row:not(:first-child) .amplify-table__th {
  border-top: none;
}

.amplify-table__row:not(:last-child) .amplify-table__th {
  border-bottom: none;
}

.amplify-table__row:hover {
  background-color:#d3d3d3;
}